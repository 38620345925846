<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Driver
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Drivers"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="drivers"
          :search.sync="search"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:top>
            <password-reset
              :username="passwordResetUsername"
              :isDialog="passwordResetDialog"
              @onClose="passwordResetDialog = false"
            />
            <v-dialog v-model="dialog" max-width="750px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="DriverForm">
                      <v-row v-if="editedIndex === -1">
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="First Name"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.middleName"
                            label="Middle Name"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last Name"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.username"
                            label="Username"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                            :rules="[rules.required, rules.email]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.driverNumber"
                            label="Driver number"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.password"
                            label="Password"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.confirmPassword"
                            label="Confirm Password"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <div><label>Date of Birth</label></div>
                          <v-date-picker
                            v-model="editedItem.dateOfBirth"
                          ></v-date-picker>
                        </v-col>
                        <!-- <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.telephone"
                            label="Telephone"
                          />
                        </v-col> -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.phoneNumber"
                            label="Phone Number"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.postcode"
                            label="Postcode"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.state"
                            :items="states"
                            label="State"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.unitNumber"
                            label="Unit Number"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.streetNumber"
                            label="Street Number"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.streetName"
                            label="Street Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.city"
                            label="Suburb"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="editedIndex > -1">
                        <v-col cols="12">
                          <v-file-input
                            truncate-length="15"
                            v-model="personalPhoto"
                            label="Personal Photo"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.driverNo"
                            label="Driver No"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="First Name"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.middleName"
                            label="Middle Name"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.phoneNumber"
                            label="Phone Number"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.homeCity"
                            label="Home City"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.licenseNo"
                            label="License No"
                          />
                        </v-col>

                        <v-col cols="12" md="6">
                          <div><label>License Issued Date</label></div>
                          <v-date-picker
                            v-model="editedItem.issuedDate"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <div><label>License Expiry Date</label></div>
                          <v-date-picker
                            v-model="editedItem.expiryDate"
                          ></v-date-picker>
                        </v-col>
                        <v-col cols="12">
                          <v-file-input
                            truncate-length="15"
                            v-model="frontSideImage"
                            label="License Front Side Image"
                          ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                          <v-file-input
                            truncate-length="15"
                            v-model="backSideImage"
                            label=" License Back Side Image"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog2" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Assign Taxi to Driver</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="DriverForm">
                      <v-row v-if="editedIndex === -1">
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="currentVehicleId"
                            :items="vehicles"
                            item-text="vehicleNo"
                            item-value="id"
                            label="Taxi"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="dialog2 = false">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="saveAssignedTaxi">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.personalPhoto`]="{ item }">
            <img :src="getProfile(item)" alt="" width="70" />
          </template>
          <template v-slot:[`item.vehicleNo`]="{ item }">
            {{ item ? getVehicle(item.ownedVehicles) : "" }}
          </template>
          <template v-slot:[`item.drivingLicense.expiryDate`]="{ item }">
            <div v-if="item.drivingLicense">
              {{ formatDate(item.drivingLicense.expiryDate) }}
            </div>
          </template>
          <template v-slot:[`item.profile.dateOfBirth`]="{ item }">
            <div v-if="item.profile">
              {{ formatDate(item.profile.dateOfBirth) }}
            </div>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <div>
              {{ formatDate(item.startDate) }}
            </div>
          </template>
          -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              medium
              color="error"
              class="mr-2"
              @click="resetPassword(item)"
            >
              mdi-lock-reset
            </v-icon>
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="info" class="mr-2" @click="assignTaxi(item)">
              mdi-taxi
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import PasswordReset from "./components/resetPassword.vue";
import moment from "moment";
export default {
  name: "DataTablesView",
  components: { PasswordReset },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) =>
        !!String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "Invalid email address.",
    },
    dialog: false,
    dialog2: false,
    passwordResetDialog: false,
    passwordResetUsername: "",
    dialogDelete: false,
    headers: [
      {
        text: "Image",
        align: "start",
        value: "personalPhoto",
      },
      {
        text: "Driver No",
        value: "driverNo",
      },
      {
        text: "Vehicle No",
        value: "vehicleNo",
      },
      { text: "First Name", value: "profile.firstName" },
      { text: "Middle Name", value: "profile.middleName" },
      { text: "Last Name", value: "profile.lastName" },
      { text: "Username", value: "user.userName" },
      { text: "Phone Number", value: "user.phoneNumber" },
      // { text: "Emergency  Number", value: "" },
      { text: "Home City", value: "homeCity" },
      { text: "DOB", value: "profile.dateOfBirth" },
      { text: "Start Date", value: "startDate" },
      { text: "Driver License Expiry", value: "drivingLicense.expiryDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    loading: false,
    options: {},
    total: 0,
    drivers: [],
    vehicles: [],
    states: ["NSW", "ACT", "QLD", "VIC", "NT", "WA", "SA"],
    currentDriverId: 0,
    currentVehicleId: 0,
    personalPhoto: [],
    frontSideImage: [],
    backSideImage: [],
    editedIndex: -1,
    editedItem: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: moment().format("YYYY-MM-DD"),
      telephone: "",
      phoneNumber: "",
      phoneNumber: "",
      address: "",
      state: "",
      unitNumber: "",
      streetNumber: "",
      streetName: "",
      postcode: "",
      city: "",
    },
    defaultItem: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: moment().format("YYYY-MM-DD"),
      telephone: "",
      phoneNumber: "",
      state: "",
      address: "",
      unitNumber: "",
      streetNumber: "",
      streetName: "",
      postcode: "",
      city: "",
    },
    search: undefined,
    searchTimerId: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Driver" : "Edit Driver";
    },
    getBaseUrl() {
      return this.$http.defaults.baseURL.replace("/api/", "");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getAllDrivers("");
      },
      deep: true,
    },
    search: {
      handler() {
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(() => {
          this.getAllDrivers(this.search);
        }, 2000);
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http
        .get("/Vehicle/GetAllIdAndName")
        .then((response) => {
          this.vehicles = response.data;
        })
        .catch(() => {});
    },
    getAllDrivers(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        skip: itemsPerPage * (page - 1),
        take: itemsPerPage,
        searchColumn: "",
        searchValue: search,
      };

      this.$http
        .post("/Driver/GetAllBySearch", data)
        .then((resposne) => {
          this.loading = false;
          this.total = resposne.data.total;
          this.drivers = resposne.data.drivers;
        })
        .catch(() => {});
    },
    editItem(item) {
      this.editedIndex = this.drivers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.driverId = item.id;
      this.editedItem.firstName = item.profile.firstName;
      this.editedItem.lastName = item.profile.lastName;
      this.editedItem.middleName = item.profile.middleName;
      this.editedItem.phoneNumber = item.user.phoneNumber;
      if (item.drivingLicense) {
        this.editedItem.phoneNumber = item.user.phoneNumber;
        this.editedItem.licenseNo = item.drivingLicense.licenseNo;
        this.editedItem.expiryDate = moment(
          item.drivingLicense.expiryDate
        ).format("YYYY-MM-DD");
        this.editedItem.issuedDate = moment(
          item.drivingLicense.issuedDate
        ).format("YYYY-MM-DD");
      } else {
        this.editedItem.issuedDate = moment().format("YYYY-MM-DD");
        this.editedItem.expiryDate = moment().format("YYYY-MM-DD");
      }
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.drivers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/Driver/HardDelete/${this.editedItem.id}`)
        .then((response) => {
          this.drivers.splice(index, 1);
          if (response.status === 200) {
            this.$toast.success("Driver deleted successfully.", "Success", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {});
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    assignTaxi(item) {
      this.currentDriverId = item.id;
      this.dialog2 = true;
    },
    saveAssignedTaxi() {
      if (this.currentVehicleId && this.currentVehicleId > 0) {
        this.$http
          .get("/Driver/AddVehicleForDriverById", {
            params: {
              driverId: this.currentDriverId,
              vehicleId: this.currentVehicleId,
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
              this.getAllDrivers();
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch(() => {});
      }
      this.dialog2 = false;
    },
    save() {
      if (this.editedIndex > -1) {
        let index = this.editedIndex;
        if (this.editedItem.phoneNumber) {
          this.$http.get("/User/UpdateContactNumber", {
            params: {
              username: this.editedItem.user.userName,
              phone: this.editedItem.phoneNumber,
            },
          });
        }
        const formData = new FormData();
        formData.append("DriverId", this.editedItem.driverId);
        formData.append("PersonalPhoto", this.personalPhoto);
        formData.append("DriverNo", this.editedItem.driverNo);
        formData.append("FirstName", this.editedItem.firstName);
        formData.append("MiddleName", this.editedItem.middleName);
        formData.append("LastName", this.editedItem.lastName);
        formData.append("HomeCity", this.editedItem.homeCity);
        formData.append("StartDate", this.editedItem.startDate);
        formData.append("LicenseNo", this.editedItem.licenseNo);
        formData.append("IssuedDate", this.editedItem.issuedDate);
        console.log(this.editedItem.expiryDate);
        formData.append("ExpiryDate", this.editedItem.expiryDate);
        formData.append("FrondSideImage", this.frontSideImage);
        formData.append("BackSideImage", this.backSideImage);
        this.$http
          .post("/Driver/UpdateProfile", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.status === "success") {
              this.drivers.splice(index, 1, response.data.data);
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch(() => {
            this.$toast.error("Something went wrong.", "Error", {
              position: "topRight",
            });
          });
        this.close();
      } else {
        if (this.$refs.DriverForm.validate()) {
          this.$http
            .post("/Authentication/register-driver", this.editedItem)
            .then((response) => {
              if (response.data.status === "Success") {
                this.drivers.unshift(response.data.data);
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            })
            .catch((err) => {
              if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                this.$toast.error(err.response.data.message, "Error", {
                  position: "topRight",
                });
              } else {
                this.$toast.error("Something went wrong.", "Error", {
                  position: "topRight",
                });
              }
            });
          this.close();
        }
      }
    },
    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY");
      } else {
        return "";
      }
    },
    formatDateTime(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY, h:mm:ss a");
      } else {
        return "";
      }
    },
    getVehicle(vehicles) {
      if (vehicles) {
        let item = vehicles.find((x) => x.active === true);
        return item ? item.vehicle.vehicleNo : "";
      } else {
        return "";
      }
    },
    getProfile(item) {
      if (item && item.user) {
        return (
          this.getBaseUrl +
          "/Resources/Users/" +
          item.user.id +
          "/Images/" +
          item.personalPhoto
        );
      } else {
        return "";
      }
    },
    resetPassword(item) {
      this.passwordResetUsername = item.user.userName;
      this.passwordResetDialog = true;
    },
  },
};
</script>
